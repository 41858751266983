const defaultColumn = {
    createdAt: {
        label: '報表生產日期',
        type: 'string',
        position: 'center',
        width: 2,
        widthPx: 50
    },
    status: {
        label: '報表狀態',
        type: 'string',
        position: 'center',
        width: 1,
        widthPx: 20
    },
    reportLink: {
        label: '下載報表',
        type: 'string',
        position: 'center',
        width: 1,
        widthPx: 20
    }
}

const contestDownloadColumn = {
    name: {
        label: '競賽報表名稱',
        type: 'string',
        position: 'center',
        width: 2,
        widthPx: 50
    },
    ...defaultColumn
}

const performanceDownloadColumn = {
    name: {
        label: '業績報表名稱',
        type: 'string',
        position: 'center',
        width: 2,
        widthPx: 50
    },
    ...defaultColumn
}

const awardsDownloadColumn = {
    name: {
        label: '獎項報表名稱',
        type: 'string',
        position: 'center',
        width: 2,
        widthPx: 50
    },
    ...defaultColumn
}

const backstageReportMode = {
    BackstageReportContest: { label: '競賽報表下載', value: 'contest' },
    BackstageReportPerformance: { label: '業績報表下載', value: 'performance' },
    BackstageReportAwards: { label: '獎項報表下載', value: 'awards' }
}

export {
    contestDownloadColumn,
    performanceDownloadColumn,
    awardsDownloadColumn,
    backstageReportMode
}
